<template>
  <div>
    <loading :loading="loading" @closeLoading="closeLoading" />

    <van-popup
      v-model="_show"
      closeable
      position="bottom"
      round
      :style="{ height: '60%' }">
      <div class="popup">
        <div class="popup-header">
          <p class="popup-header-p">购买</p>
          <div class="popup-header-commodity" v-for="(x, i) in all_guige" :key="x.i" v-show="i == index">
            <img :src="x[3]" alt="">
            <div class="popup-header-commodity-right">
              <p>{{ name }}</p>
              <div>
                <span style="color: #E54320;">￥</span>
                <span style="color: #E54320; font-size: 0.4865rem;">{{ x[1] }}</span>
                <span v-if="isPostHorse == '1' && x[4]">
                  <span style="color: #E54320; font-size: 0.4865rem;">/</span>
                  <span style="color: #E54320;">￥</span>
                  <span style="color: #E54320; font-size: 0.4865rem;">{{ x[4] }}+{{ x[5] }}</span>
                  <span style="color: #E54320;">驿马</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="popup-kong" v-if="!cates.length">
          <span class="popup-kong-span">统一规格</span>
        </div>

        <div class="popup-main">
          <div  class="popup-main-item" v-for=" (x, i) in cates" :key=" i">
            <p>{{ x.specName }}</p>
            <span style="margin-right: 0.1351rem;" v-for="(y, j) in x.guige" :key="j">
              <van-button
                @click="guige_btn(i, j, y.specValueName)"
                :class="{'popup-main-item-btn': index_arr[i] == j}">
                <div class="popup-main-item-btn-name">{{ y.specValueName }}</div>
              </van-button>
            </span>
          </div>
        </div>

        <div class="popup-footer">
          <div class="popup-footer-quantity">
            <p>购买数量</p>
            <span>
              <van-stepper
                disable-input
                v-model="quantity" 
                integer 
                :disabled="$route.query.affiliation == '2'" />
            </span>
          </div>

          <span>
            <van-button 
              :loading="btnLoading"
              round 
              type="primary" 
              block 
              color="#E54320"
              @click="addCart_goumai"
            >{{ type == 1 ? '加入购物车' : '立即购买' }}</van-button>
          </span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getGuige, addCart } from '@/api/flMall'
import { Toast } from 'vant'
import Loading from './Loading'
export default {
  components: {
    Loading
  },

  props: {
    show: {
      type: Boolean,
      required: true
    },
    type: {
      type: Number
    },
    isPostHorse: {
      type: String
    }
  },

  data () {
    return {
      cates: [],
      all_guige: [],
      quantity: 1,
      name: '',
      skuId: '',
      shopId: '',

      index: 0,
      index_arr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      specValueName_arr: [],
      loading: false,
      btnLoading: false
    }
  },

  computed: {
    _show: {
      get () {
        return this.show
      },
      set () {
        this.$parent.show = false
      }
    }
  },

  methods: {
    getPage () {
      this.btnLoading = true
      const id = this.$route.query.id
      getGuige(id).then(res => {
        if (res.data.code === 0) {
          this.name = res.data.data.goods_spu.name
          this.skuId = res.data.data.goods_skus[0].id
          this.shopId = res.data.data.goods_spu.shopId

          const specType = res.data.data.goods_spu.specType
          let cates = []
          let goodsSkus = JSON.parse(JSON.stringify(res.data.data.goods_skus))
          // 长度表示规格分类个数
          let specs = res.data.data.goods_skus[0].specs
          this.btnLoading = false
          if (specType == '0') {
            this.cates = []
          } else {
            goodsSkus.forEach(x => {
              x.specs.forEach(y => {
                delete y.createTime
                delete y.id
                delete y.skuId
                delete y.sort
                delete y.spuId
                delete y.tenantId
                delete y.updateTime
              })
            })

            specs.forEach(() => {
              cates.push({
                specName: '',
                guige: []
              })
            })

            cates.forEach((x, i) => {
              goodsSkus.forEach(y => {
                x.guige.push(y.specs[i])
              })
              x.guige = Array.from(new Set(x.guige.map(x => JSON.stringify(x)))).map(x => JSON.parse(x))
              x.specName = x.guige[0].specName
            })
            this.cates = cates
            cates.forEach((x, i) => {
              this.$set(this.specValueName_arr, i, x.guige[0].specValueName)
            })
          }

          let all_guige = []
          goodsSkus.forEach((x, i) => {
            all_guige[i] = []
            all_guige[i].push(x.id)
            all_guige[i].push(x.salesPrice)
            all_guige[i].push(x.integrals)
            all_guige[i].push(x.picUrl)
            x.specs.forEach(x => {
              all_guige[i].push(x.specValueName)
            })
            all_guige[i].push(x.mixedMoney)
            all_guige[i].push(x.mixedRice)
          })
          this.all_guige = JSON.parse(JSON.stringify(all_guige))
          console.log(this.all_guige, '000')
        }
      })
    },

    guige_btn (i, j, y) {
      this.$set(this.index_arr, i, j)
      this.$set(this.specValueName_arr, i, y)
      this.all_guige.forEach((x, i) => {
        const isInclude = this.specValueName_arr.every(y => x.includes(y))
        if (isInclude) {
          this.index = i
          this.skuId = x[0]
        }
      })
    },

    addCart_goumai () {
      const data = [{
        spuId: this.$route.query.id,
        skuId: this.skuId,
        shopId: this.shopId,
        quantity: this.quantity
      }]
      if (this.type == '1') {
        this.loading = true
        addCart(data).then(res => {
          if (res.data.code === 0) {
            Toast('添加成功')
            this.$parent.show = false
            this.loading = false
          }
        })
      } else {
        const guige = [{
          spuId: this.$route.query.id,
          skuId: this.skuId,
          count: this.quantity,
          ge_id: this.shopId
        }]
        this.$router.push({
          path: '/flzhifu',
          query: {
            guige: JSON.stringify(guige),
            session: this.$route.query.session,
            shopIds: this.shopId,
            id: this.$route.query.id,
            ticketActive: this.$route.query.ticketActive,
            advertisementId: this.$route.query.advertisementId,
            advertisementType: this.$route.query.advertisementType
          }
        })
      }
    },

    closeLoading () {
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  height: 100%;
  padding: 0.4054rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  &-header {
    &-p {
      font-size: 0.4595rem;
      color: #333;
      margin-bottom: 0.2703rem;

    }
    &-commodity {
      display: flex;
      img {
        width: 2.7027rem;
        height: 2.7027rem;
        margin-right: 0.2703rem;
      }
      &-right {
        padding: 0.1351rem 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p {
          font-size: 0.3784rem;
          color: #333;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
  }
  &-kong {
    font-size: 0.3514rem;
    display: flex;
    margin-top: 0.4054rem;
    &-span {
      background: #eee;
      padding: 0.1351rem 0.2703rem;
      border-radius: 0.1351rem;
    }
  }
  &-main {
    flex: 1;
    margin-top: 0.4054rem;
    &-item {
      margin-bottom: 0.1351rem;
      p {
        margin-bottom: 0.1351rem;
        font-size: 0.3784rem;
        color: #333;
      }
      .van-button {
        max-width: 80%;
        padding: 0 0.4865rem;
        margin-bottom: 0.1351rem;
        height: 28px;
        border-radius: 5px;
        background: #F5F5F5;
      }
      &-btn {
        max-width: 80%;
        color: #FB2B53 !important;
        border: 1px solid #FB2B53;
        &-name {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
      }
    }
  }
  &-main > span {
    margin-right: 0.2703rem;
  }
  &-footer {
    p {
      font-size: 0.3784rem;
      color: #333;
    }
    .van-button {
      height: 1.0811rem;
    }
    &-quantity {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.8108rem;
    }
  }
}
</style>